  /* @font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../assets/fonts/fira-sans-condensed-v10-latin-300.woff2') format('woff2');
  } */
  /* fira-sans-condensed-regular - latin */
  @font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../assets/fonts/fira-sans-condensed-v10-latin-regular.woff2') format('woff2');
  }
  /* fira-sans-condensed-700 - latin */
  @font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../assets/fonts/fira-sans-condensed-v10-latin-700.woff2') format('woff2');
  }
  /* @font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 800;
    src: local(''), url('../assets/fonts/fira-sans-condensed-v10-latin-800.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('../assets/fonts/fira-sans-condensed-v10-latin-900.woff2') format('woff2');
  } */

  /* fira-sans-condensed-800 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../assets/fonts/nunito-v26-latin-300.woff2') format('woff2');
  }
  /* fira-sans-condensed-900 - latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../assets/fonts/nunito-v26-latin-700.woff2') format('woff2');
  }

.text-nunito-light {
    font-family: 'Nunito';
    font-weight: 300;
}

.text-nunito-bold {
    font-family: 'Nunito';
    font-weight: 700;
}

.text-special {
    background: linear-gradient(30deg, #ea55f7, #ab4aff);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.wavedash {

}

@media (max-width: 1023px) {
    .wavedash {
        display: flex;
        justify-content: flex-end;
    }

    .wavedash img {
        height: 500px;
        width: auto;
    }
}


@media (max-width: 767px) {
    .wavedash img {
        height: 400px;
    }
}

.redacted {
    display: inline-block;
    vertical-align: middle;
    background: linear-gradient(30deg, #ea55f7, #ab4aff);
    border-radius: 0.1em;
    box-shadow: 0 0 1px rgba(0,0,0,0.35);
    width: 300px;
    height: 1.2em;
    transform: skewY(-5deg) rotate(5deg);
}